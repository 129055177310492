import React, { useEffect, useState, useRef } from 'react'
import { suscriptoorService } from '../../services'
import { Grid } from '@mui/material'
import { getUrlImage, panelSuscription } from '../utils/Utils'
import styled from 'styled-components'
import BatteryChargingFullIcon from '@mui/icons-material/BatteryChargingFull'
import Battery4BarIcon from '@mui/icons-material/Battery4Bar'
import BatteryAlertIcon from '@mui/icons-material/BatteryAlert'
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation'
import SpeedIcon from '@mui/icons-material/Speed'
import { APIProvider, Map, Marker, useMap } from '@vis.gl/react-google-maps'

const ControlMap = (props) => {
    const map = useMap()

    const [cars, setCars] = React.useState<any[]>([])

    useEffect(() => {
        if (!map || !props.lat || !props.lng) return

        map.setOptions({
            center: { lat: props.lat, lng: props.lng },
            zoom: 10,
            styles: [
                {
                    featureType: 'poi',
                    stylers: [{ visibility: 'off' }],
                },
            ],
        })

        const marker = new window.google.maps.Marker({
            map,
            position: { lat: props.lat, lng: props.lng },
            icon: '/markerRentActivo.png',
        })

        map.setZoom(16)
    }, [map, props.lat, props.lng])

    return <></>
}

export default function HeaderSus(props) {
    const [{ sus }, setState] = React.useState<any>({
        sus: {},
    })

    useEffect(() => {
        searchData()
    }, [props.id])

    const searchData = () => {
        suscriptoorService.one(props.id, (data, error) => {
            if (data && data.data) {
                setState((currentState) => ({
                    ...currentState,
                    ['sus']: data.data,
                }))
            }
        })
    }

    return (
        <>
            {sus['id'] && (
                <PanelInfo container>
                    <Grid item sm={2} xs={12}>
                        <PanelImg>
                            <img
                                src={getUrlImage(sus['car']['picture'])}
                                alt={sus['car']['name']}
                            />
                        </PanelImg>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <PanelCar>
                            <p>
                                {sus['car']['brand']} - {sus['car']['model']}{' '}
                            </p>
                            <p>{sus['car']['number']}</p>
                        </PanelCar>
                        <PanelCarDetail>
                            <p>
                                <></>
                                <span>
                                    <SpeedIcon
                                        style={{
                                            width: '2em',
                                            fontSize: '2em',
                                        }}
                                    />{' '}
                                </span>{' '}
                                <span>{sus['car']['km'].toFixed(2)} km</span>
                            </p>
                            <p>
                                <></>{' '}
                                <span>
                                    <LocalGasStationIcon
                                        style={{
                                            width: '2em',
                                            fontSize: '2em',
                                            color:
                                                sus['car']['fuelPercent'] > 50
                                                    ? 'green'
                                                    : sus['car'][
                                                          'fuelPercent'
                                                      ] > 30
                                                    ? 'orange'
                                                    : 'red',
                                        }}
                                    />
                                </span>
                                <span>
                                    {sus['car']['fuelPercent'].toFixed(2)}%
                                </span>
                            </p>
                            <p>
                                <></>{' '}
                                <span>
                                    {sus['car']['engine_status'] > 0 ? (
                                        <img
                                            src="/ic_on.png"
                                            alt="mobify"
                                            style={{ width: '50px' }}
                                        />
                                    ) : (
                                        <img
                                            src="/ic_off.png"
                                            alt="mobify"
                                            style={{ width: '50px' }}
                                        />
                                    )}
                                </span>
                                <span>
                                    {sus['car']['engine_status'] == 1
                                        ? 'Encendido'
                                        : 'Apagado'}
                                </span>
                            </p>
                            <p>
                                <></>{' '}
                                <span>
                                    {sus['car']['isCloseDoorAndKey'] ? (
                                        <img
                                            src="/ic_closelabel.png"
                                            alt="mobify"
                                            style={{ width: '50px' }}
                                        />
                                    ) : (
                                        <img
                                            src="/ic_openlabel.png"
                                            alt="mobify"
                                            style={{ width: '50px' }}
                                        />
                                    )}
                                </span>
                                <span>
                                    {sus['car']['isCloseDoorAndKey']
                                        ? 'Cerrado'
                                        : 'Abierto'}
                                </span>
                            </p>
                        </PanelCarDetail>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <APIProvider apiKey={process.env.REACT_APP_GMAPS!}>
                            <Map
                                style={{
                                    width: '100%',
                                    height: '350px',
                                }}
                            ></Map>
                            <ControlMap
                                lat={sus['car']['lat']}
                                lng={sus['car']['lng']}
                            />
                        </APIProvider>
                    </Grid>
                </PanelInfo>
            )}
        </>
    )
}

const PanelInfo = styled(Grid)`
    min-height: 220px;
    background: #83c63b77;
    padding: 1em;
    border-radius: 6px;
    font-size: 0.8em;
    line-height: 1em;
    align-items: center;
    width: 100%;
    margin-bottom: 2em;

    & p {
        display: flex;
        flex-direction: row;
        align-items: center;

        & * {
            margin-right: 10px;
        }
    }

    @media (max-width: 767px) {
        margin: 0.2em;
    }
`

const PanelImg = styled.div`
    background: white;
    width: 200px;
    height: 200px;
    max-width: 90%;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;

    & img {
        width: 90%;
    }
`

const PanelCar = styled.div`
    font-weight: bold;
    font-size: 1.5em;
`

const PanelCarDetail = styled.div`
    & p {
        display: flex;
    }
`
