import React, { useEffect, useState, useRef } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { Grid, Button, Modal, TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { userService, rentService } from '../../../services'
import CrudEmpty from '../../crud/CrudEmpty'
import Form from '../../form/Form'
import HeaderSus from '../../sus/HeaderSus'
import styled from 'styled-components'
import { ToastContainer, toast } from 'react-toastify'
import { Box } from '@mui/system'

const moment = require('moment')

const markers: google.maps.Marker[] = []

type NewRentParams = {
    id: string
}

const initElementLine = {
    user: { id: null, name: '', email: '' },
    suscription: { id: null, name: '' },
    start: null,
    end: null,
}

export default function EditRent() {
    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 1000,
        maxWidth: '80%',
        bgcolor: 'white',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    }

    const navigate = useNavigate()
    const { t, i18n } = useTranslation()
    var { id } = useParams<NewRentParams>()
    var aux = {
        suscription: { id: id },
    }

    const [
        {
            object,
            error,
            openModalConfirmFree,
            openModalConfirmPay,
            money,
            motive,
        },
        setState,
    ] = React.useState<any>({
        object: { ...initElementLine, ...aux },
        error: '',
        openModalConfirmFree: false,
        openModalConfirmPay: false,
        money: 0,
        motive: '',
    })

    const estructureform = [
        {
            type: 'input',
            name: 'user.email',
            service: userService.combo,
            col: 4,
        },
        {
            type: 'input',
            name: 'start',
            inputtype: 'datetime-local',
            col: 4,
        },
        {
            type: 'input',
            name: 'end',
            inputtype: 'datetime-local',
            col: 4,
        },
        {
            type: 'autocomplete',
            name: 'rentalState',
            col: 4,
            options: [
                {
                    key: 'PENDINGACCEPT',
                    name: 'Pendiente de aceptación',
                },
                {
                    key: 'ACCEPT',
                    name: 'Aceptado',
                },
                {
                    key: 'PROBLEMFIANCE',
                    name: 'Problemas con la fianza',
                },
                {
                    key: 'START',
                    name: 'Iniciado',
                },
                {
                    key: 'END',
                    name: 'Finalizado',
                },
            ],
            readonly: true,
        },
        {
            type: 'input',
            name: 'start_real',
            inputtype: 'datetime-local',
            col: 4,
        },
        {
            type: 'input',
            name: 'end_real',
            inputtype: 'datetime-local',
            col: 4,
        },
        {
            type: 'input',
            name: 'km_start',
            col: 2,
        },
        {
            type: 'input',
            name: 'km_end',
            col: 2,
        },
        {
            type: 'input',
            name: 'fuel_level_start',
            col: 2,
        },
        {
            type: 'input',
            name: 'fuel_level_end',
            col: 2,
        },
        {
            type: 'title',
            name: 'titleempty',
            col: 4,
        },
        {
            type: 'checkbox',
            name: 'freeFiance',
            col: 4,
        },
        {
            type: 'input',
            name: 'amountFiance',
            col: 4,
        },
        {
            type: 'input',
            name: 'motiveFiance',
            col: 4,
        },
    ]

    useEffect(() => {
        searchData()
    }, [])

    const searchData = () => {
        rentService.one(id, (data, error) => {
            if (data && data.data) {
                setState((currentState) => ({
                    ...currentState,
                    ['object']: data.data,
                }))
            }
        })
    }

    const closeModalConfirmFree = () => {
        setState((currentState) => ({
            ...currentState,
            ['openModalConfirmFree']: false,
        }))
    }

    const closeModalConfirmPay = () => {
        setState((currentState) => ({
            ...currentState,
            ['openModalConfirmPay']: false,
        }))
    }

    const cancelForm = (object) => {
        window.history.back()
    }

    const saveForm = (object) => {
        rentService.create(object, (data, fail) => {
            if (data && data.data) {
                window.location.href = '/rental/edit/' + data.data['id']
            } else {
                setState((currentState) => ({
                    ...currentState,
                    ['error']: fail.mes,
                }))
            }
        })
    }

    const closeModal = () => {
        setState((currentState) => ({
            ...currentState,
            ['error']: '',
        }))
    }

    const closeCar = (id) => {
        rentService.closecar(id, (data, fail) => {
            if (data && data.data) {
                toast.success('Se ha enviado la acción', {
                    position: toast.POSITION.TOP_CENTER,
                })
            } else {
                setState((currentState) => ({
                    ...currentState,
                    ['error']: fail.mes,
                }))
            }
        })
    }

    const openCar = (id) => {
        rentService.openCar(id, (data, fail) => {
            if (data && data.data) {
                toast.success('Se ha enviado la acción', {
                    position: toast.POSITION.TOP_CENTER,
                })
            } else {
                setState((currentState) => ({
                    ...currentState,
                    ['error']: fail.mes,
                }))
            }
        })
    }

    const end = () => {
        setState((currentState) => ({
            ...currentState,
            ['openModalConfirmFree']: true,
        }))
    }

    const endPay = () => {
        setState((currentState) => ({
            ...currentState,
            ['openModalConfirmPay']: true,
        }))
    }

    const sendFreeFiance = () => {
        rentService.freefiance(id, (data, fail) => {
            if (data && data.data) {
                window.location.reload()
            } else {
                setState((currentState) => ({
                    ...currentState,
                    ['error']: fail.mes,
                }))
            }
        })
    }

    const cancel = () => {
        rentService.cancel(id, (data, fail) => {
            if (data && data.data) {
                window.location.reload()
            } else {
                setState((currentState) => ({
                    ...currentState,
                    ['error']: fail.mes,
                }))
            }
        })
    }

    const sendPayFiance = () => {
        var body = {
            amountFiance: money,
            motiveFiance: motive,
        }

        rentService.payfiance(id, body, (data, fail) => {
            if (data && data.data) {
                window.location.reload()
            } else {
                setState((currentState) => ({
                    ...currentState,
                    ['error']: fail.mes,
                }))
            }
        })
    }

    return (
        <CrudEmpty i18n={'rent'} goback={true}>
            <Grid container>
                <Grid xs={12}>
                    <HeaderSus
                        id={
                            object['suscription']
                                ? object['suscription']['id']
                                : -1
                        }
                    />
                </Grid>
                <Grid xs={12}>
                    <PanelForm>
                        <Form
                            t={t}
                            i18n={'rent'}
                            objectform={object}
                            fields={estructureform}
                            searchFilter={saveForm}
                            cleanFilter={cancelForm}
                            notierror={null}
                            onlyRead={true}
                            isHiddenCancel={true}
                        />
                    </PanelForm>
                </Grid>
                {object && object['rentalState'] == 'START' && (
                    <Grid
                        xs={12}
                        style={{ marginTop: '1em', textAlign: 'right' }}
                    >
                        <Button
                            variant="outlined"
                            color="warning"
                            style={{ marginLeft: '30px' }}
                            onClick={() => {
                                openCar(object.car.id)
                            }}
                        >
                            {' '}
                            Abrir puertas
                        </Button>
                        <Button
                            variant="outlined"
                            color="warning"
                            style={{ marginLeft: '30px' }}
                            onClick={() => {
                                closeCar(object.car.id)
                            }}
                        >
                            {' '}
                            Cerrar puertas
                        </Button>
                        <Button
                            variant="outlined"
                            color="warning"
                            style={{ marginLeft: '30px' }}
                            onClick={() => {
                                end()
                            }}
                        >
                            {' '}
                            Finalizar y liberar fianza
                        </Button>
                        <Button
                            variant="outlined"
                            color="warning"
                            style={{ marginLeft: '30px' }}
                            onClick={() => {
                                endPay()
                            }}
                        >
                            {' '}
                            Finalizar y cobrar parte de la fianza
                        </Button>
                    </Grid>
                )}
                {object &&
                    object['rentalState'] != 'START' &&
                    object['rentalState'] != 'END' &&
                    object['rentalState'] != 'CANCEL' && (
                        <Grid
                            xs={12}
                            style={{ marginTop: '1em', textAlign: 'right' }}
                        >
                            <Button
                                variant="outlined"
                                color="warning"
                                style={{ marginLeft: '30px' }}
                                onClick={() => {
                                    cancel()
                                }}
                            >
                                {' '}
                                Cancelar
                            </Button>
                        </Grid>
                    )}
            </Grid>
            {error != '' && (
                <ModalBack>
                    <div>
                        <p>{error}</p>
                        <Button
                            onClick={closeModal}
                            variant="contained"
                            color="secondary"
                            style={{
                                margin: '10px 0 30px',
                            }}
                        >
                            OK
                        </Button>
                    </div>
                </ModalBack>
            )}
            <ToastContainer />
            <Modal
                open={openModalConfirmFree}
                onClose={closeModalConfirmFree}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <Box sx={style}>
                    <div style={{ marginBottom: '30px' }}>
                        ¿Quieres liberar la fianza? ¿Esta todo correcto y no
                        habra que reclamar nada al cliente?
                    </div>
                    <div>
                        <Button
                            variant="outlined"
                            color="primary"
                            style={{ marginRight: '30px' }}
                            onClick={closeModalConfirmFree}
                        >
                            {' '}
                            Cancelar
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                sendFreeFiance()
                            }}
                        >
                            {' '}
                            Liberar{' '}
                        </Button>
                    </div>
                </Box>
            </Modal>
            <Modal
                open={openModalConfirmPay}
                onClose={closeModalConfirmPay}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <Box sx={style}>
                    <div style={{ marginBottom: '30px' }}>
                        ¿Deseas cobrar parte de la finza? Indica la cantidad y
                        el motivo
                    </div>
                    <div style={{ marginBottom: '10px' }}>
                        <TextField
                            variant="standard"
                            sx={{ width: '100%' }}
                            id={'motive'}
                            name={'motive'}
                            label={'Motivo'}
                            value={motive}
                            onChange={(evt) => {
                                setState((currentState) => ({
                                    ...currentState,
                                    ['motive']: evt.target.value,
                                }))
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            autoComplete="off"
                        />
                    </div>
                    <div style={{ marginBottom: '30px' }}>
                        <TextField
                            variant="standard"
                            sx={{ width: '100%' }}
                            id={'money'}
                            name={'money'}
                            label={'Cantidad'}
                            value={money}
                            onChange={(evt) => {
                                setState((currentState) => ({
                                    ...currentState,
                                    ['money']: evt.target.value,
                                }))
                            }}
                            type={'number'}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            autoComplete="off"
                        />
                    </div>
                    <div>
                        <Button
                            variant="outlined"
                            color="primary"
                            style={{ marginRight: '30px' }}
                            onClick={closeModalConfirmPay}
                        >
                            {' '}
                            Cancelar
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={sendPayFiance}
                        >
                            {' '}
                            Cobrar{' '}
                        </Button>
                    </div>
                </Box>
            </Modal>
        </CrudEmpty>
    )
}

const ModalBack = styled.div`
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.4);
    z-index: 2000;
    align-items: center;
    justify-content: center;

    & div {
        background: white;
        border-radius: 6px;
        padding: 1em;
        text-align: center;
        width: 600px;
        max-width: 80%;
    }
`

const PanelForm = styled.div`
    background: white;
    border-radius: 6px;
    padding: 15px;
`
