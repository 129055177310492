import { Grid, Button } from '@mui/material'
import moment from 'moment'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const selectIconCar = (car) => {
    var result = '/markerFree.png'

    if (car['statusCar'] && car['statusCar'] == 'INSHARE') {
        if (car['speed'] && car['speed'] > 0) {
            result = '/markerShareActivo.png'
        } else {
            result = '/markerShareInactivo.png'
        }
    } else if (car['statusCar'] && car['statusCar'] == 'INRENT') {
        if (car['speed'] && car['speed'] > 0) {
            result = '/markerRentActivo.png'
        } else {
            result = '/markerRentInactivo.png'
        }
    } else if (car['statusCar'] && car['statusCar'] == 'BLOCK') {
        result = '/markerBlock.png'
    } else if (car['statusCar'] && car['statusCar'] == 'RESERVED') {
        result = '/markerRentInactivo.png'
    }

    return result
}

const PanelSuscription = styled.div`
    background: white;
    margin: 10px;
    border: 1px solid #ccc;
    border-radius: 6px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;

    & img {
        width: 100px;
        max-width: 80%;
    }

    & h2 {
        font-size: 1em;
        line-height: 1em;
        margin-top: -0.7em;
    }

    & p {
        line-height: 1em;
        margin: 0 0 10px;
    }
`

export const getUrlImage = function (namefile) {
    if (namefile) {
        if (namefile.startsWith('http')) {
            return namefile
        } else {
            var nameImage = namefile
            nameImage = nameImage.startsWith('/') ? nameImage : '/' + nameImage
            return process.env.REACT_APP_HOST + '/image' + nameImage
        }
    } else {
        return ''
    }
}

export const textStateSus = (sus) => {
    if (sus['rental']['rentalState'] == 'PENDINGACCEPT') {
        return 'Pendiente de aceptación'
    } else if (sus['rental']['rentalState'] == 'ACCEPT') {
        return 'Aceptada'
    } else if (sus['rental']['rentalState'] == 'PROBLEMFIANCE') {
        return 'Problemas con la fianza'
    } else if (sus['rental']['rentalState'] == 'START') {
        return 'Iniciado'
    } else if (sus['rental']['rentalState'] == 'END') {
        return 'Finalizado'
    } else {
        return ''
    }
}

export const panelSuscription = (sus) => {
    return (
        <PanelSuscription>
            <img
                src={getUrlImage(sus['car']['picture'])}
                alt={sus['car']['name']}
            />
            <h2>{sus['car']['number']}</h2>
            {sus['rental'] && sus['rental']['id'] && <p>{textStateSus(sus)}</p>}
            {(sus['rental'] == null || sus['rental']['id'] == null) && (
                <p
                    style={{
                        color: 'green',
                        fontWeight: 'bold',
                    }}
                >
                    LIBRE
                </p>
            )}
            {sus['rental'] &&
                sus['rental']['start'] &&
                sus['rental']['start'] != '' && (
                    <p style={{ color: '#cdcdcd', fontSize: '0.8em' }}>
                        Iniciado el:{' '}
                        {moment(new Date(sus['rental']['start'])).format(
                            'DD/MM/YYYY HH:mm'
                        )}
                    </p>
                )}
            {sus['rental'] &&
                sus['rental']['start'] &&
                sus['rental']['start'] != '' && (
                    <p style={{ color: '#cdcdcd', fontSize: '0.8em' }}>
                        Conductor: {sus['rental']['user']['name']}{' '}
                        {sus['rental']['user']['lastname']}
                    </p>
                )}
            {(sus['rental'] == null || sus['rental']['id'] == null) && (
                <Button
                    component={Link}
                    to={'/rental/new/' + sus['id']}
                    variant="contained"
                    color="secondary"
                    style={{
                        margin: '10px 0 30px',
                    }}
                >
                    Asignar
                </Button>
            )}
            {sus['rental'] && sus['rental']['id'] && (
                <Button
                    component={Link}
                    to={'/rental/edit/' + sus['rental']['id']}
                    variant="contained"
                    color="warning"
                    style={{
                        margin: '10px 0 30px',
                    }}
                >
                    GESTIONAR
                </Button>
            )}
        </PanelSuscription>
    )
}
