import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import Form from '../form/Form'
import 'toasted-notes/src/styles.css'
import Layout from '../layout/Layout'
import { useTranslation } from 'react-i18next'
import Card from '@mui/material/Card'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const moment = require('moment')

type CrudFormParams = {
    id: string
}

export default function CrudForm(props) {
    const navigate = useNavigate()
    const { t, i18n } = useTranslation()
    var { id } = useParams<CrudFormParams>()

    const titleEdit = props.titleEdit
        ? props.titleEdit
        : t(props.i18n + '.titleformedit')
    const titleNew = props.titleNew
        ? props.titleNew
        : t(props.i18n + '.titleformnew')

    const initNullable = () => {
        var init: any = null

        return init
    }

    const createEmpty = () => {
        var ar: any[] = []

        return ar
    }

    const [
        {
            idRow,
            objectform,
            titleWindow,
            reload,
            code,
            labelCode,
            estructureform,
            haveother,
            othersform,
            onlyRead,
            aux,
        },
        setState,
    ] = useState({
        idRow: id ? Number(id) : props.id ? props.id : 0,
        objectform: props.objectform ? props.objectform : {},
        titleWindow: (id && Number(id)) || props.id ? titleEdit : titleNew,
        reload: false,
        code: initNullable(),
        labelCode: props.labelCode ? props.labelCode : 'Buscar por código',
        estructureform: props.estructureform ? props.estructureform : {},
        haveother: props.haveother ? props.haveother : false,
        othersform: props.othersform ? props.othersform : null,
        onlyRead: props.onlyRead ? props.onlyRead : false,
        aux: props.stateform ? props.stateform : [],
    })

    const service = props.service ? props.service : null
    const recoveryElement = props.recoveryElement ? props.recoveryElement : null
    const valid = props.valid ? props.valid : null
    const urlCancel = props.urlCancel ? props.urlCancel : ''
    const urledit = props.urledit ? props.urledit : ''
    const buttonSave = props.buttonSave ? props.buttonSave : t('general.save')
    const buttonCancel = props.buttonCancel
        ? props.buttonCancel
        : props.onlyRead
        ? t('general.return')
        : t('general.return')
    const haveotherTop = props.haveotherTop ? props.haveotherTop : null
    const othersformTop = props.othersformTop ? props.othersformTop : null

    const searchOne = function () {
        service.one(idRow, (data, error) => {
            if (data && data.data) {
                var element = data.data
                let objectedit = JSON.parse(JSON.stringify(element))
                if (recoveryElement) {
                    objectedit = recoveryElement(objectedit, element)
                    if (props.checkIsReadOnly) {
                        var update2 = {
                            objectform: objectedit,
                            reload: true,
                            onlyRead: props.checkIsReadOnly(objectedit),
                        }

                        setState((currentState) => ({
                            ...currentState,
                            ...update2,
                        }))
                    } else {
                        var update = {
                            objectform: objectedit,
                            reload: true,
                        }

                        setState((currentState) => ({
                            ...currentState,
                            ...update,
                        }))
                    }
                } else {
                    var update3 = {
                        objectform: element,
                        reload: true,
                    }

                    setState((currentState) => ({
                        ...currentState,
                        ...update3,
                    }))
                }
            }
        })
    }

    const cancelForm = (object) => {
        if (urlCancel && urlCancel != '') {
            navigate(urlCancel)
        } else {
            navigate(-1)
        }
    }

    const saveForm = (object) => {
        var isValid = valid == null
        var message = null
        if (!isValid) {
            message = valid(object)
            isValid = message == null
            if (props.notierror) {
                props.notierror()
            }
        }
        if (isValid) {
            if (props.beforeSave) {
                object = props.beforeSave(object)
            }
            var objToSave = cleanElementsBeforeSave(object)
            if (idRow && idRow > 0) {
                objToSave['id'] = idRow
                update(objToSave, false)
            } else {
                create(objToSave, false)
            }
        } else {
            toast.error(message, {
                position: toast.POSITION.TOP_CENTER,
            })
        }
    }

    const create = (object, cleanSerie) => {
        service.create(object, (data, error) => {
            if (data) {
                toast.success(t('general.saveok'), {
                    position: toast.POSITION.TOP_CENTER,
                })
                setState((currentState) => ({
                    ...currentState,
                    ['idRow']: Number(data.data.id),
                }))
                navigate(urledit + data.data.id)
            } else if (error) {
                if (error.server) {
                    toast.error(error.mes, {
                        position: toast.POSITION.TOP_CENTER,
                    })
                } else {
                    toast.error(t(error.mes), {
                        position: toast.POSITION.TOP_CENTER,
                    })
                }

                if (props.notierror) {
                    props.notierror()
                }
            }
        })
    }

    const update = (object, cleanSerie) => {
        setState((currentState) => ({ ...currentState, ['reload']: false }))
        service.edit(idRow, object, (data, error) => {
            if (data) {
                toast.success(t('general.saveok'), {
                    position: toast.POSITION.TOP_CENTER,
                })
                var objectedit = recoveryElement(object, data.data)
                var update = {
                    objectform: objectedit,
                    reload: true,
                }

                setState((currentState) => ({ ...currentState, ...update }))
            } else if (error) {
                if (error.server) {
                    toast.error(error.mes, {
                        position: toast.POSITION.TOP_CENTER,
                    })
                } else {
                    toast.error(t(error.mes), {
                        position: toast.POSITION.TOP_CENTER,
                    })
                }

                if (props.notierror) {
                    props.notierror()
                }
            }
        })
    }

    const cleanElementsBeforeSave = function (object) {
        let newObj = JSON.parse(JSON.stringify(object))
        return newObj
    }

    useEffect(() => {
        setState((currentState) => ({
            ...currentState,
            ['estructureform']: props.estructureform,
        }))
    }, [props.estructureform])

    useEffect(() => {
        var update = {
            objectform: props.objectform,
            reload: true,
        }

        setState((currentState) => ({ ...currentState, ...update }))
    }, [props.objectform])

    useEffect(() => {
        if (!id && props.initTask) {
            setState((currentState) => ({ ...currentState, ['reload']: false }))
            props.initTask()
        }

        if (props.initeffect) {
            props.initeffect(setStateAux)
        }

        return () => {
            setState({
                idRow: 0,
                objectform: {},
                titleWindow: titleNew,
                reload: false,
                code: initNullable(),
                labelCode: 'Buscar por código',
                estructureform: {},
                haveother: false,
                othersform: null,
                onlyRead: false,
                aux: [],
            })
        }
    }, [])

    useEffect(() => {
        if (idRow && idRow > 0) {
            searchOne()
        }

        setState((currentState) => ({
            ...currentState,
            ['titleWindow']: idRow && idRow > 0 ? titleEdit : titleNew,
        }))

        return () => {
            //setObjectform({})
            //setIdrow(null)
        }
    }, [idRow])

    const searchByCode = (code) => {
        let filter = {}
        filter[props.fieldcode] = code
        service.pageFilter(0, 1, filter, (data, error) => {
            if (data && data.data && data.data.length == 1) {
                toast.success('Cargado correctamente', {
                    position: toast.POSITION.TOP_CENTER,
                })
                var update = {
                    objectform: data.data[0],
                    code: '',
                    titleWindow: t(props.i18n + '.titleformedit'),
                    idRow: data.data[0]['id'],
                }

                setState((currentState) => ({ ...currentState, ...update }))
            } else {
                setState((currentState) => ({ ...currentState, ['code']: '' }))
                toast.error('No se ha encontrado', {
                    position: toast.POSITION.TOP_CENTER,
                })
            }
        })
    }

    var setStateAux = (name, value) => {
        var auxDefault = { ...aux }
        if (Array.isArray(name)) {
            name.forEach((element) => {
                auxDefault[element] = value[element]
            })
        } else {
            auxDefault[name] = value
        }
        setState((currentState) => ({
            ...currentState,
            ['aux']: auxDefault,
        }))
    }
    var moreActions = (getObject) => {
        if (props.moreActions) {
            return props.moreActions(getObject, aux, setStateAux)
        } else {
            return <></>
        }
    }

    return (
        <Layout
            t={t}
            title={titleWindow}
            search={props.search}
            code={code}
            label={labelCode}
            setCode={setState}
            searchByCode={searchByCode}
        >
            <Grid container spacing={3}>
                {haveotherTop && haveotherTop() && !onlyRead && othersformTop}
                <Grid item sm={12} xs={12}>
                    <Card sx={{ padding: '2em' }}>
                        <Form
                            t={t}
                            i18n={props.i18n}
                            objectform={objectform}
                            fields={estructureform}
                            searchFilter={saveForm}
                            cleanFilter={cancelForm}
                            labelsave={buttonSave}
                            labelcancel={buttonCancel}
                            reload={reload}
                            haveMoreActions={
                                props.moreActions ? props.moreActions : false
                            }
                            moreActions={moreActions}
                            forcesave={
                                props.forcesave != null
                                    ? props.forcesave
                                    : false
                            }
                            onlyRead={onlyRead}
                            notierror={props.notierror ? props.notierror : null}
                        />
                    </Card>
                </Grid>
                {props.haveInside &&
                    props.haveInside(aux, setStateAux, objectform)}
                {haveother && idRow > 0 && (
                    <Grid item sm={12} xs={12}>
                        {' '}
                        {othersform}{' '}
                    </Grid>
                )}
            </Grid>
            <ToastContainer />
        </Layout>
    )
}
