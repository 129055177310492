import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import './App.css'
import './language/i18n'
import { isNotLoginLogin } from './utils/session'
import LoginRoute from './components/route/LoginRoute'
import Login from './components/pages/login/Login'
import Dashboard from './components/pages/dasboard/Dashboard'
import PrivateRoute from './components/route/PrivateRoute'
import Rent from './components/pages/rent/Rent'
import Bill from './components/pages/bill/Bill'
import Alerts from './components/pages/alerts/Alerts'
import FormMyAccount from './components/pages/users/FormMyAccount'
import NewRent from './components/pages/rent/NewRent'
import EditRent from './components/pages/rent/EditRent'

export default function App() {
    const isLoggedNotIn = isNotLoginLogin()

    return (
        <>
            <Router>
                <Routes>
                    <Route
                        path="/login"
                        element={
                            <LoginRoute>
                                <Login />
                            </LoginRoute>
                        }
                    />
                    <Route
                        path="/dashboard"
                        element={
                            <PrivateRoute>
                                <Dashboard />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/myaccount/:id"
                        element={
                            <PrivateRoute>
                                <FormMyAccount />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/rental/new/:id"
                        element={
                            <PrivateRoute>
                                <NewRent />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/rental/edit/:id"
                        element={
                            <PrivateRoute>
                                <EditRent />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/rent"
                        element={
                            <PrivateRoute>
                                <Rent />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/bill"
                        element={
                            <PrivateRoute>
                                <Bill />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/alerts"
                        element={
                            <PrivateRoute>
                                <Alerts />
                            </PrivateRoute>
                        }
                    />
                    {isLoggedNotIn ? (
                        <Route path="/" element={<Login />} />
                    ) : (
                        <Route
                            path="/"
                            element={
                                <PrivateRoute>
                                    <Dashboard />
                                </PrivateRoute>
                            }
                        />
                    )}
                </Routes>
            </Router>
        </>
    )
}
