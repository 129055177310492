import React, { useEffect, useState, useRef } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { Grid, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { suscriptoorService, userService, rentService } from '../../../services'
import CrudEmpty from '../../crud/CrudEmpty'
import Form from '../../form/Form'
import HeaderSus from '../../sus/HeaderSus'
import styled from 'styled-components'

const moment = require('moment')

const markers: google.maps.Marker[] = []

type NewRentParams = {
    id: string
}

const initElementLine = {
    user: { id: null, name: '', email: '' },
    suscription: { id: null, name: '' },
    start: null,
    end: null,
}

export default function NewRent() {
    const navigate = useNavigate()
    const { t, i18n } = useTranslation()
    var { id } = useParams<NewRentParams>()
    var aux = {
        suscription: { id: id },
    }

    const [{ sus, object, error }, setState] = React.useState<any>({
        sus: {},
        object: { ...initElementLine, ...aux },
        error: '',
    })

    const estructureform = [
        {
            type: 'input',
            name: 'user.email',
            service: userService.combo,
            col: 4,
        },
        {
            type: 'input',
            name: 'start',
            inputtype: 'datetime-local',
            col: 4,
        },
        {
            type: 'input',
            name: 'end',
            inputtype: 'datetime-local',
            col: 4,
        },
    ]

    useEffect(() => {
        searchData()
    }, [])

    const searchData = () => {
        suscriptoorService.one(id, (data, error) => {
            if (data && data.data) {
                setState((currentState) => ({
                    ...currentState,
                    ['sus']: data.data,
                }))
            }
        })
    }

    const cancelForm = (object) => {
        window.history.back()
    }

    const saveForm = (object) => {
        rentService.create(object, (data, fail) => {
            if (data && data.data) {
                window.location.href = '/rental/edit/' + data.data['id']
            } else {
                setState((currentState) => ({
                    ...currentState,
                    ['error']: fail.mes,
                }))
            }
        })
    }

    const closeModal = () => {
        setState((currentState) => ({
            ...currentState,
            ['error']: '',
        }))
    }

    return (
        <CrudEmpty i18n={'rent'} goback={true}>
            <Grid container>
                <Grid xs={12}>
                    <HeaderSus id={id} />
                </Grid>
                <Grid xs={12}>
                    <PanelForm>
                        <Form
                            t={t}
                            i18n={'rent'}
                            objectform={object}
                            fields={estructureform}
                            searchFilter={saveForm}
                            cleanFilter={cancelForm}
                            notierror={null}
                        />
                    </PanelForm>
                </Grid>
            </Grid>
            {error != '' && (
                <ModalBack>
                    <div>
                        <p>{error}</p>
                        <Button
                            onClick={closeModal}
                            variant="contained"
                            color="secondary"
                            style={{
                                margin: '10px 0 30px',
                            }}
                        >
                            OK
                        </Button>
                    </div>
                </ModalBack>
            )}
        </CrudEmpty>
    )
}

const ModalBack = styled.div`
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.4);
    z-index: 2000;
    align-items: center;
    justify-content: center;

    & div {
        background: white;
        border-radius: 6px;
        padding: 1em;
        text-align: center;
        width: 600px;
        max-width: 80%;
    }
`

const PanelForm = styled.div`
    background: white;
    border-radius: 6px;
    padding: 15px;
`
