import BaseService from './_base.service';

const URL = "/appsuscriptor/rental";

export const rentService = {
    search,
    combo,
    page,
    pageFilter,
    one,
    create,
    edit,
    remove,
    removeLogic,
    cancel,
    end,
    freefiance,
    payfiance,
    closecar,
    openCar
};

function search(offset,callback) {
    return BaseService.get(URL+"/all/", callback,false,false)
}

function combo(callback) {
    return BaseService.get(URL+"/combo/", callback, false,false)
}

function page(page,size,callback) {
    return BaseService.get(URL+"/page/"+page+"/"+size, callback, false,false)
}

function cancel(id,callback) {
    return BaseService.get(URL+"/cancel/"+id, callback, false,false)
}

function openCar(id,callback) {
    return BaseService.get(URL+"/opencar/"+id, callback, false,false)
}

function closecar(id,callback) {
    return BaseService.get(URL+"/closecar/"+id, callback, false,false)
}

function end(id,callback) {
    return BaseService.get(URL+"/end/"+id, callback, false,false)
}

function freefiance(id,callback) {
    return BaseService.get(URL+"/end/free/"+id, callback, false,false)
}

function payfiance(id,body, callback) {
    return BaseService.post(URL+"/end/pay/"+id, body, callback, false,false)
}

function pageFilter(page,size,filter,callback) {
    return BaseService.post(URL+"/page/"+page+"/"+size, filter, callback, false,false)
}

function one(id,callback) {
    return BaseService.get(URL + "/one/"+id, callback);
}


function create(bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.post(URL, body, callback, true)
}

function edit(id, bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.put(URL+"/"+id, body, callback, true)
}

function remove(id,callback) {
    return BaseService.delete(URL+"/"+id, callback);
}

function removeLogic(id,callback) {
    return BaseService.delete(URL+"/"+id+"/logic", callback);
}





