import React, { useEffect, useState, useRef } from 'react'
import {
    rentService as service,
    carService,
    billService,
    suscriptoorService,
} from '../../services'
import { Grid } from '@mui/material'
import { panelSuscription } from '../utils/Utils'

export default function PanelSus() {
    const [{ suscriptors }, setState] = React.useState<any>({
        suscriptors: [],
    })

    useEffect(() => {
        searchData()
    }, [])

    const searchData = () => {
        suscriptoorService.all((data, error) => {
            if (data && data.data) {
                var aux: any[] = []
                data.data.forEach((element) => {
                    if (element.lat && element.lng) {
                        aux.push(element)
                    }
                })
                setState((currentState) => ({
                    ...currentState,
                    ['suscriptors']: data.data,
                }))
            }
        })
    }

    return (
        <Grid container>
            {suscriptors != null &&
                suscriptors.length > 0 &&
                suscriptors.map((sus) => (
                    <Grid xs={12} sm={3} md={2}>
                        {panelSuscription(sus)}
                    </Grid>
                ))}
        </Grid>
    )
}
